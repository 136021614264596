<template>
  <page-main
    title="Ok! Let's take a look at your Resume!"
    :aside="[
      `Review your resume, then send it to the ${displayName} Career Center to get it reviewed.`,
      'If you don\'t need a review, download your resume as a PDF or Word document.',
    ]"
    noBottom
    :error="error"
  >
    <template v-slot:nav>
      <div class="pt-2 pt-sm-4">
        <v-system-bar
          color="accent"
          height="5"
          class="mb-2 mb-sm-4"
        ></v-system-bar>
        <resume-selector
          :type.sync="resumeType"
          title="Change Format"
          :disabled="!canProceedEmail"
          @update:title="updateTitle($event)"
          @update:desc="updateDesc($event)"
        ></resume-selector>
        <p class="text-h6 mb-0 pt-2 pt-sm-4">{{ resumeTitle }}</p>
        <p class="text-body-2 text--secondary font-italic mb-2">
          {{ resumeDesc }}
        </p>
      </div>
    </template>
    <v-container style="position: relative" class="pt-0 pt-md-3">
      <v-overlay color="primary" v-if="overlayFull">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-overlay color="primary" :absolute="true" v-if="overlay">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="6">
          <div>
            <p class="text-h6 mb-0">Send your resume for review</p>
            <p class="text-body-2 text--secondary font-italic mb-2">
              The {{ displayName }} Career Center will review your resume and
              give you feedback.
            </p>
          </div>
          <submit-for-review
            @submit="submitResumeReview($event)"
          ></submit-for-review>
        </v-col>
        <v-col cols="12" sm="5" md="4" lg="4" class="d-flex align-end wide">
          <div class="wide">
            <v-btn
              block
              outlined
              @click="downloadPdf()"
              id="download-pdf"
              class="my-2"
              >Download PDF</v-btn
            >
            <v-btn block outlined @click="downloadWord()" id="download-word"
              >Download Word (.docx)</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <div v-if="errorOnLoad">
            <div
              class="d-flex flex-column justify-center align-center error-box"
            >
              <div class="pb-5 error-box-content">
                <h1>Ouch!</h1>
                <h2>We couldn't load your resume!</h2>
              </div>
              <div class="error-box-content">
                <div class="font-italic pb-2">
                  We're looking into the problem - in the mean time you can try
                  reloading the page:
                </div>
                <v-btn @click="refresh()" outlined>Refresh</v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="resume-backing pa-3 mb-4 pb-1">
              <img
                v-if="resumeImageSrc.length > 0"
                :src="resumeImageSrc"
                class="resume-img"
                alt="Resume View"
              />
              <div v-else class="resume-placeholder"></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as log from "../utility/logging/logger";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import DataAccess from "../report/DataAccess.js";
import * as ReportAPI from "../api/ReportApi.js";
import ReportBuilder from "../reportBuilder/ReportBuilder";
import ResumeSelector from "../components/ResumeSelector.vue";
import SubmitForReview from "../components/SubmitForReview.vue";
import * as careerApi from "../api/CareerApiUser";

// @ is an alias to /src
export default {
  name: "Review2",
  components: {
    "resume-selector": ResumeSelector,
    "submit-for-review": SubmitForReview,
  },
  data: () => ({
    resName: "FirstName, LastName",
    fileName: "Resume",
    address: "Address | Email | Phone",
    professonalSummary: "Professional Summary Text",
    skills: "Skills and Abilities Text",
    education: [],
    workExp: [],
    hardSkillsDisp: "",
    volunteerDisp: "",
    professionalOrgDisp: "",
    professionalCertDisp: "",
    interestsDisp: "",
    renderReport: {},
    reportRequest: {},
    builder: {},
    token: "",
    dateExpires: {},
    overlay: true,
    overlayFull: false,
    errorOnLoad: false,
    error: "",
    resumeType: "",
    imgData: "",
    resumeTitle: "",
    resumeDesc: "",
  }),
  async mounted() {
    this.dateExpires = new Date(new Date().getTime() + -60 * 1000); // set to one minute ago to init
    this.resumeType = "Professional"; // triggers watch on resumeType
  },
  computed: {
    ...mapFields("name", { nameDisplay: "displayValue" }),
    ...mapFields("address", { addressDisplay: "displayValue" }),
    ...mapFields("hardSkill", ["hardSkills", "hardSkillLabels"]),
    ...mapFields("softSkill", ["softSkills"]),
    ...mapFields("licenseCert", ["licensesCertsDisplay"]),
    ...mapFields("adjective", ["selectedAdjectives", "adjectiveDisplay"]),
    ...mapFields("role", ["selectedRoles"]),
    ...mapFields("language", ["languagesDisplay"]),
    ...mapFields("organization", [
      "volunteerOrgDisplay",
      "professionalOrgDisplay",
    ]),
    ...mapFields("personalInterest", ["personalInterestsDisplay"]),
    ...mapFields("careerInterest", ["careerInterestsDisplay"]),
    ...mapFields("work", ["workDict"]),
    ...mapFields("education", ["educationDict"]),
    ...mapFields("settings", ["reportURL"]),
    ...mapFields("finalInfo", ["otherSkills", "points"]),
    ...mapGetters("education", { eduList: "fullList" }),
    ...mapGetters("work", { workList: "fullList" }),
    ...mapFields("auth", {
      emailConfirmed: "emailConfirmed",
      authType: "auth.authType",
    }),
    ...mapFields("envStyle", ["displayName"]),
    canProceedEmail() {
      return this.authType != "resumation" || this.emailConfirmed;
    },
    resumeImageSrc() {
      if (this.imgData.length === 0) {
        return "";
      } else {
        return this.imgData;
      }
    },
  },
  watch: {
    resumeType: {
      async handler() {
        await this.initReportBuilder(this.resumeType);
      },
    },
  },
  methods: {
    updateDesc(desc) {
      this.resumeDesc = desc;
    },
    updateTitle(title) {
      this.resumeTitle = title;
    },
    async initReportBuilder(resumeType) {
      try {
        this.overlay = true;
        this.errorOnLoad = false;

        if (!this.canProceedEmail) {
          this.errorOnLoad = true;
          this.overlay = true;
          this.error =
            "You must confirm your email address to proceed. You can re-send a confirmation email from the User Options page.";
          return;
        }

        // Get Token
        let da = new DataAccess(this.reportURL);
        let token = await this.getToken();
        if (token === null) {
          log.logError(
            "Token was not retrieved successfully!",
            "Review.initReportBuilder"
          );
          this.errorOnLoad = true;
          this.overlay = false;
          return;
        }

        // Setup reportBuilder, build reportRequest object
        this.buildReportRequest(resumeType);

        // Retrieve image from report server
        let image = await da.GetReportPNG(this.reportRequest, token);
        this.imgData = URL.createObjectURL(image);

        this.overlay = false;
      } catch (error) {
        log.logError(
          "Unhandled error in initReportBuilder code",
          "Review.initReportBuilder"
        );
        log.logError(`${error.name}: ${error.message}`);
        this.errorOnLoad = true;
        this.overlay = false;
      }
    },
    refresh() {
      log.logWarning(
        "User triggered refresh of Resume page due to error.",
        "Review.refresh"
      );
      window.location.href = "/Review";
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    buildReportRequest(resumeType) {
      this.builder = new ReportBuilder(resumeType);

      this.reportRequest = this.builder.BuildReport(
        this.nameDisplay,
        this.addressDisplay,
        this.adjectiveDisplay,
        this.hardSkills,
        this.hardSkillLabels,
        this.softSkills,
        this.selectedRoles,
        this.careerInterestsDisplay,
        this.eduList,
        this.workList,
        this.workDict,
        this.languagesDisplay,
        this.licensesCertsDisplay,
        this.professionalOrgDisplay,
        this.volunteerOrgDisplay,
        this.personalInterestsDisplay,
        this.otherSkills,
        this.points
      );
    },
    async getToken() {
      if (new Date() > this.dateExpires) {
        var tokenResult = await ReportAPI.GetReportToken();
        if (tokenResult.success) {
          this.token = tokenResult.data.access_token;
          this.dateExpires = new Date(
            new Date().getTime() + tokenResult.data.expires_in * 1000
          );
          return this.token;
        } else {
          return null;
        }
      } else {
        return this.token;
      }
    },
    async downloadPdf() {
      try {
        let da = new DataAccess(this.reportURL);

        var token = await this.getToken();
        if (token === null) {
          log.logError(
            "Token was not retrieved successfully!",
            "Review.downloadPdf"
          );
          this.error =
            "Ouch! We had an unexpected error while downloading your Resume.";
          return;
        }

        let report = await da.GetReportPDF(this.reportRequest, token);
        this.downloadFile(report, this.buildFileName("pdf"));
      } catch (error) {
        log.logError(
          "Unhandled error while downloading pdf",
          "Review.downloadPdf"
        );
        this.error =
          "Ouch! We had an unexpected error while downloading your Resume.";
      }
    },
    buildFileName(extension) {
      return `${this.nameDisplay} Resume.${extension}`;
    },
    async downloadWord() {
      let report = await this.getDocx();
      this.downloadFile(report, this.buildFileName("docx"));
    },
    async getDocx() {
      return await this.builder.BuildDocx(
        this.nameDisplay,
        this.addressDisplay,
        this.adjectiveDisplay,
        this.hardSkills,
        this.hardSkillLabels,
        this.softSkills,
        this.selectedRoles,
        this.careerInterestsDisplay,
        this.eduList,
        this.workList,
        this.workDict,
        this.languagesDisplay,
        this.licensesCertsDisplay,
        this.professionalOrgDisplay,
        this.volunteerOrgDisplay,
        this.personalInterestsDisplay,
        this.otherSkills,
        this.points
      );
    },
    downloadFile(blob, fileName) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    // todo: move to common method
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result.slice(reader.result.indexOf("base64,", 0) + 7));
        reader.onerror = (error) => reject(error);
      });
    },
    // get the resume file as a base64 docx
    async submitResumeReview(data) {
      this.overlayFull = true;
      const errorText =
        "Oops! An unexpected error occured. You can try again, or upload your resume through the 'Submit Custom Resume' page.";
      try {
        let report = await this.getDocx();
        var reportBase64 = await this.toBase64(report);
        var result = await careerApi.CreateReview(
          data.name,
          data.note,
          reportBase64,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          this.buildFileName("docx")
        );
        if (!result.success) {
          this.error = errorText;
        } else {
          this.overlayFull = false;
          this.nav("/SubmittedResumes");
        }
      } catch (er) {
        log.logError(
          `Error in submitResumeReview: ${er}`,
          "Review.submitResumeReview"
        );
        this.error = errorText;
      } finally {
        this.overlayFull = false;
      }
    },
  },
};
</script>

<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  max-width: 636px;
}

.resume-img {
  max-width: 100%;
  height: auto;
}
</style> 